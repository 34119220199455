<template src="./TextModule.html"/>

<script>
import SpeakerModule from '@/components/SpeakerModule/SpeakerModule.vue';

export default {
  name: "TextModule",
  components: { SpeakerModule },
  data() {
    return {
      timerid: -1,
      currentSpeaker: -1,
      currentLine: 0,
      totalLines: 5,
      output: "",
      speakers: [
        {
          name: "Claire",
          speaking: true,
          pattern: [1, 1, 1, 1]
        },
        {
          name: "Erin",
          speaking: true,
          pattern: [1, 0, 0, 1]
        },
        {
          name: "Henry",
          speaking: true,
          pattern: [0, 0, 1, 0]
        },
        {
          name: "Noah",
          speaking: true,
          pattern: [0, 1, 1, 1]
        },
        {
          name: "Olivia",
          speaking: true,
          pattern: [0, 1, 0, 0]
        },
        {
          name: "Ryan",
          speaking: true,
          pattern: [1, 0, 1, 0]
        },
        {
          name: "Sam",
          speaking: true,
          pattern: [1, 1, 1, 0]
        },
        {
          name: "Yvette",
          speaking: true,
          pattern: [1, 0, 0, 1]
        }
      ]
    };
  },
  computed: {
    active() {
      let active = 0;
      for (const speaker in this.speakers) {
        if (this.speakers[speaker].speaking) {
          active++;
        }
      }
      return active;
    }
  },
  methods: {
    timer() {
      this.timerid = setTimeout(this.timerCallback, 500);
    },
    timerCallback() {
      if (this.output.length > 15000) {
        this.reset();
        return;
      }
      this.currentSpeaker++;
      if (this.currentSpeaker >= this.speakers.length) {
        this.currentSpeaker = 0;
        this.currentLine++;
      }
      if (this.currentLine >= this.totalLines) {
        this.currentLine = 0;
      }
      if (this.speakers[this.currentSpeaker].speaking) {
        this.speak(this.currentSpeaker, this.currentLine);
        this.timer();
      } else if (this.active > 0) {
        this.timerCallback();
      }
    },
    speak(speakerIdx, lineIdx) {
      if (this.speakers[speakerIdx].pattern[lineIdx] === 0) {
        this.speaking('<span class="no">No</span>');
      } else {
        this.speaking('<span class="yes">Yes</span>');
      }
    },
    prepare() {
      for (const speaker of this.speakers) {
        let line = [
          '<em>*',
          speaker.name,
          ' sits quietly'
        ];
        // if (speaker.speaking) {
        //   line.push(' clears throat');
        // } else {
        //   line.push(' looks annoyed');
        // }
        line.push('*</em>');
        line.push('<br>');

        if (!speaker.speaking) {
          this.speaking(line.join(''));
        }
      }
    },
    reset() {
      clearTimeout(this.timerid);
      this.output = '';
      this.prepare();
      this.currentSpeaker = -1;
      this.currentLine = 0;
      this.timer();
    },
    speaking(line) {
      this.output = this.output + " " + line;
    }
  },
  mounted() {
    this.prepare();
    this.timer();
  },
};
</script>

<style scoped lang="scss">
@import 'TextModule.scss';
</style>
