<template src="./ChessboardModule.html"/>

<script>
import ChessBoard from "chessboardjs-vue3";

export default {
  name: "ChessboardModule",
  data() {
    return {
      board: null,
      position: ''
    };
  },
  emits: ['keyChanged'],
  computed: {},
  methods: {
    change(oldPos, newPos) {
      this.position = ChessBoard.objToFen(newPos);
      this.$emit('keyChanged', this.position);
    }
  },
  mounted() {
    this.board = ChessBoard('board', {
      draggable: true,
      dropOffBoard: 'trash',
      sparePieces: true,
      imagesPath: "/types",
      onChange: this.change
    });
  }
};
</script>

<style lang="scss">
@import 'ChessboardModule.scss';
</style>
