<template>
  <div>
    <!--    <div class="image">-->
    <!--      <img alt="Yes No" src="./assets/yesno.png">-->
    <!--    </div>-->
    <h1><span class="yes">Yes?</span> <span class="no">No?</span></h1>
    <div class="link">
      <a :href="gcWebsite">{{ gcWebsite }}</a>
    </div>
  </div>
  <div class="sections">
    <div>
      <ChessboardModule @keyChanged="checkBoard"/>
      <div class="solved-text" v-html="message"></div>
    </div>
    <QuestionsModule/>
    <TextModule/>
  </div>
</template>

<script>
import TextModule from '@/components/TextModule/TextModule.vue';
import ChessboardModule from '@/components/ChessboardModule/ChessboardModule.vue';
import QuestionsModule from '@/components/QuestionsModule/QuestionsModule.vue';

export default {
  name: 'YesNo',
  components: {
    QuestionsModule,
    ChessboardModule,
    TextModule,
  },
  data() {
    return {
      message: "Drag pieces to the board to place<br>Drag away from the board to remove.",
      solved: false
    };
  },
  computed: {
    gcWebsite() {
      return 'https://coord.info/' + process.env.VUE_APP_GCCODE;
    },
  },
  methods: {
    handleApi(data) {
      if (data.status === 200 && this.solved === false) {
        this.message = data.body.message;
      }
    },
    checkBoard(key) {
      const payload = btoa(key);
      const api = process.env.VUE_APP_API_URL + '/' + payload;
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      fetch(api, requestOptions)
          .then(response => response.json().then(data => ({ status: response.status, body: data })))
          .then(data => this.handleApi(data))
          .catch((error) => {
            alert('Error from backend. Reload and try again later.');
            throw error;
          });
    }
  }
};
</script>

<style lang="scss">
:root {
  --color-green: #00b500;
  --color-red: #b50000;
}

body {
  background: #2c3e50;
  color: #fff;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
}

a {
  color: inherit;
  font-weight: bold;
}

h1 {
  text-align: center;
  margin: 0;
  padding: 0;
}

.yes {
  color: var(--color-green);
}

.no {
  color: var(--color-red);
}

.link {
  text-align: center;
  padding: 10px 0;
}

.solved-text {
  font-size: 32px;
  line-height: 130%;
  text-align: center;
}

//#app > div {
//  margin-inline: auto;
//  width: 100%;
//  max-width: 600px;
//}
.sections {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 30px;

  > div {
    width: 100%;
    max-width: 600px;
  }
}
</style>
