<div>
    <div class="speaker-list">
        <template v-for="speaker in speakers">
            <div>
                <input type="checkbox" :id="`speaker-`+speaker.name" v-model="speaker.speaking" @change="reset">
                <label :for="`speaker-`+speaker.name">
                    {{ speaker.name }}
                    <img src="@/assets/account-voice.svg" alt="Mute" width="24" height="24" loading="lazy"
                         v-if="speaker.speaking"/>
                    <img src="@/assets/account-voice-off.svg" alt="Mute" width="24" height="24" loading="lazy" v-else/>
                </label>
            </div>
        </template>
    </div>
    <div class="output" v-html="output"/>
</div>
