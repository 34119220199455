<template src="./QuestionsModule.html"/>

<script>
export default {
  name: "QuestionsModule",
  data() {
    return {
      questions: [
        "Is EarthCache at A1?", //Yes
        "Is Traditional at A8?", //Yes
        "Is Mystery at C6?", //Yes

        "Is Multi at F4?", //No
        "Is Virtual at D4?", //Yes
        "Is Webcam at B2?", //No

        "Is EarthCache at B1?", //No
        "Is Traditional at H1?", //Yes
        "Is Mystery at F8?", //No

        "Is Multi at F2?", //Yes
        "Is Virtual at A8?", //No
        "Is Webcam at G7?", //Yes
      ]
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@import 'QuestionsModule.scss';
</style>
