<template src="./SpeakerModule.html"/>

<script>
export default {
  name: "SpeakerModule",
  props: {
    name: String,
    pattern: Array,
    speaking: Boolean,
    delay: Number,
    active: Number
  },
  emits: ['speaking'],
  data() {
    return {
      current: 0,
      timer: null
    };
  },
  methods: {
    reset() {
      this.current = 0;
      if (this.speaking) {
        this.$emit('speaking', '<em>*' + this.name + ' clears throat*</em><br>');
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(this.speak, (1 + this.delay) * 500);
    },
    speak() {
      if (this.speaking) {
        let speak;
        if (this.pattern[this.current] === 0) {
          speak = this.name + '<span class="no">No</span>';
        } else {
          speak = this.name + '<span class="yes">Yes</span>';
        }
        this.current++;
        if (this.current >= this.pattern.length) {
          this.current = 0;
        }
        this.$emit('speaking', speak);
      }
      this.timer = setTimeout(this.speak, this.active * 500);
    },
  },
  mounted() {
    // setTimeout(this.speak, this.delay * 500);
    this.reset();
  },
  watch: {
    active: {
      handler() {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.reset, 50);
        // this.reset();
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import 'SpeakerModule.scss';
</style>
